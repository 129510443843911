<template>
    <div class="action-workday">
        <template>
            <div class="message">
                <div class="message-title f-18 f-bold">지금은 상담 가능한 시간이 아닙니다.</div>
                <div class="message-content m-t-15">
                    <div>
                        <p>고객센터 운영 시간에 빠르게 답변드리겠습니다.</p>
                        <p>양해해주셔서 감사합니다😊</p>
                    </div>
                    <div class="grey-box m-t-8">
                        <p class="c-grey-07 f-bold f-12">고객센터 운영시간</p>
                        <p class="f-16 f-bold c-black">평일 오전 9시 ~ 오후 6시</p>
                        <p class="f-13 c-black">(공휴일 제외)</p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionWorkingTime',
}
</script>

<style scoped lang="scss">
.grey-box {
    background: $blue-background !important;
}
</style>
